import React, { useContext, useEffect } from 'react';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import './privacypolicy.css'
import AuthContext from '../context/AuthContext';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const {  user } = useContext(AuthContext);

  const navigate = useNavigate();

  const gotoHomePage = () => {
    navigate('/');
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      {user &&  <Navbar/>}
        <div className="privacy-container">
      {/* Introduction Section */}
      <section className="privacy-section">
      <Button onClick={gotoHomePage}>Got to Homepage</Button>
        <h1 className="privacy-heading">Privacy Policy</h1>
        <p className="privacy-paragraph">This Privacy Policy describes how we collect, use, and share your personal information when you visit or make a purchase from our website.</p>
      </section>

      {/* Information Collection Section */}
      <section className="privacy-section">
        <h2 className="privacy-subheading">Information We Collect</h2>
        <p className="privacy-paragraph">We collect various types of information, including information you provide directly to us, information collected automatically, and information we receive from third parties.</p>
      </section>

      {/* Use of Information Section */}
      <section className="privacy-section">
        <h2 className="privacy-subheading">How We Use Your Information</h2>
        <p className="privacy-paragraph">We use the information we collect for a variety of purposes, including to fulfill orders, provide customer service, improve our website, and comply with legal obligations.</p>
      </section>

      {/* Sharing of Information Section */}
      <section className="privacy-section">
        <h2 className="privacy-subheading">Sharing Your Information</h2>
        <p className="privacy-paragraph">We may share your information with third parties in certain circumstances, such as to comply with legal requirements, protect our rights, or provide services to you.</p>
      </section>

      {/* Security Section */}
      <section className="privacy-section">
        <h2 className="privacy-subheading">Security</h2>
        <p className="privacy-paragraph">We take the security of your personal information seriously and implement reasonable measures to protect it. However, no method of transmission over the Internet or method of electronic storage is 100% secure.</p>
      </section>

      {/* Your Rights Section */}
      <section className="privacy-section">
        <h2 className="privacy-subheading">Your Rights</h2>
        <p className="privacy-paragraph">You have certain rights regarding your personal information, including the right to access, correct, or delete the information we hold about you.</p>
      </section>

      {/* Changes to This Policy Section */}
      <section className="privacy-section">
        <h2 className="privacy-subheading">Changes to This Policy</h2>
        <p className="privacy-paragraph">We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons.</p>
      </section>

      {/* Contact Information Section */}
      <section className="privacy-section">
        <h2 className="privacy-subheading">Contact Information</h2>
        <p className="privacy-paragraph">If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info@company.com" className="privacy-link">info@company.com</a>.</p>
      </section>
    </div>
        <MyFooter/>
    </div>
    
  );
};

export default PrivacyPolicy;
