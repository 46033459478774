import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import MyFooter from '../Components/MyFooter'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './report-summary.css'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ShareIcon from '@mui/icons-material/Share';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
// import { Col, Container, Row } from 'react-bootstrap';
import { Container, Row, Col } from 'react-grid-system';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { API } from '../api-service';
import Swal from 'sweetalert2';
import moment from 'moment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';
import kalpurush from './Kalpurush';

const ReportSummary = () => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [month,setMonth] = useState(null);
  const [year,setYear] = useState(null);
  const { user,authTokens } = useContext(AuthContext);
  const token = authTokens?.access;
  // const token = 'token';
  // const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [quranStudy, setQuranStudy] = useState(null);
  const [quranStudyDays, setQuranStudyDays] = useState(null);
  const [quranStudyDaysPrev, setQuranStudyDaysPrev] = useState(null);
  const [quranMemorize, setQuranMemorize] = useState(null);
  const [quranDars, setQuranDars] = useState(null);
  const [hadithStudy, setHadithStudy] = useState(null);
  const [hadithStudyDays, setHadithStudyDays] = useState(null);
  const [hadithStudyDaysPrev, setHadithStudyDaysPrev] = useState(null);
  const [hadithMemorize, setHadithMemorize] = useState(null);
  const [hadithDars, setHadithDars] = useState(null);
  const [bookStudyIslamic, setBookStudyIslamic] = useState(null);
  const [bookStudyIslamicPrev, setBookStudyIslamicPrev] = useState(null);
  const [bookStudyOther, setBookStudyOther] = useState(null);
  const [salatJamat, setSalatJamat] = useState(null);
  const [salatJamatPrev, setSalatJamatPrev] = useState(null);
  const [salatKaja, setSalatKaja] = useState(null);
  const [memberContact, setMemberContact] = useState(null);
  const [memberContactPrev, setMemberContactPrev] = useState(null);
  const [workerContact, setWorkerContact] = useState(null);
  const [workerContactPrev, setWorkerContactPrev] = useState(null);
  const [distributionIslamic, setDistributionIslamic] = useState(null);
  const [distributionIslamicPrev, setDistributionIslamicPrev] = useState(null);
  const [distributionOther, setDistributionOther] = useState(null);
  const [meetDawahTarget, setMeetDawahTarget] = useState(null);
  const [meetDawahTargetPrev, setMeetDawahTargetPrev] = useState(null);
  const [meetWorker, setMeetWorker] = useState(null);
  const [meetWorkerPrev, setMeetWorkerPrev] = useState(null);
  const [meetMember, setMeetMember] = useState(null);
  const [timeSpend, setTimeSpend] = useState(null);
  const [timeSpendPrev, setTimeSpendPrev] = useState(null);
  const [selfCriticism, setSelfCriticism] = useState(null);
  const [selfCriticismPrev, setSelfCriticismPrev] = useState(null);
  const [familyMeeting, setFamilyMeeting] = useState(null);
  const [familyMeetingPrev, setFamilyMeetingPrev] = useState(null);
  const [socialWork, setSocialWork] = useState(null);
  const [socialWorkPrev, setSocialWorkPrev] = useState(null);
  const [travel, setTravel] = useState(null);
  const [travelPrev, setTravelPrev] = useState(null);
  const [debt, setDebt] = useState(null);
  const [debtPrev, setDebtPrev] = useState(null);
  const [report, setReport] = useState(null);
  const [reportPrev, setReportPrev] = useState(null);
  const [comment, setComment] = useState('');

  const [gotSummary, setGotSummary] = useState(false);
  const [allSummary, setAllSummary] = useState([]);
  const [summaryId, setSummaryId] = useState(0);
  const minimumDate=new Date('2022-01-01');

  const downloadPdf = () => {
    const input = document.querySelector('.report-summary-middle-part');

    // Temporarily apply styles to ensure black text
    input.style.color = 'black';
    input.querySelectorAll('*').forEach(el => {
      el.style.color = 'black';
    });

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('monthly-summary.pdf');

      // Revert the styles back after PDF generation
      input.style.color = '';
      input.querySelectorAll('*').forEach(el => {
        el.style.color = '';
      });
    });
};

const getMonthName = (date) => {
  return date.toLocaleString('default', { month: 'long' });
};


const generatePdf = () => {
  const doc = new jsPDF();

  // Add the custom font to jsPDF
  // doc.addFileToVFS('kalpurush ANSI.ttf', kalpurush);
  // doc.addFont('kalpurush.ttf', 'kalpurush', 'normal');
  // doc.setFont('kalpurush'); // Set the font

  // Get the current month and year
  const currentMonth = getMonthName(selectedDate); // Example: 'September'
  const currentYear = selectedDate.getFullYear();  // Example: 2024 

  // Define the title of the report with the month and year
  const title = `Report Summary - ${currentMonth} ${currentYear}`;
  doc.setFontSize(16);
  doc.text(title, 15, 15);

  // Date
  doc.setFontSize(12);
  doc.text(`Date: ${selectedDate.toLocaleDateString()}`, 15, 25);

  // Define the custom layout text for the data
  const reportContent = [
    `Quran(Days): Days-${quranStudyDays}, Study-${quranStudy}, Memorization-${quranMemorize}, Dars-${quranDars}, Quality-${quranStudyDays > quranStudyDaysPrev ? 'Improved' : 'Decreased'}`,
    `Hadith(Number): Days-${hadithStudyDays}, Study-${hadithStudy}, Memorization-${hadithMemorize}, Dars-${hadithDars}, Quality-${hadithStudyDays > hadithStudyDaysPrev ? 'Improved' : 'Decreased'}`,
    `Literature(Page Number): Islamic-${bookStudyIslamic}, Others-${bookStudyOther}, Quality-${bookStudyIslamic > bookStudyIslamicPrev ? 'Improved' : 'Decreased'}`,
    `Namaz: Jamat-${salatJamat}, Kaja-${salatKaja}, Quality-${salatJamat > salatJamatPrev ? 'Improved' : 'Decreased'}`,
    `Contact: Worker Contact-${workerContact}, Member Contact-${memberContact}, Quality-${memberContact > memberContactPrev ? 'Improved' : 'Decreased'}`,
    `Distribution: Islamic-${distributionIslamic}, Others-${distributionOther}, Quality-${distributionIslamic > distributionIslamicPrev ? 'Improved' : 'Decreased'}`,
    `Meet: Dawah Target-${meetDawahTarget}, Worker-${meetWorker}, Member-${meetMember}, Quality-${meetDawahTarget > meetDawahTargetPrev ? 'Improved' : 'Decreased'}`,
    `Org Time: Time Spend-${timeSpend}, Quality-${timeSpend > timeSpendPrev ? 'Improved' : 'Decreased'}`,
    `Miscellaneous: Self Criticism-${selfCriticism}, Social Work-${socialWork}, Debt-${debt}, Quality-${selfCriticism > selfCriticismPrev ? 'Improved' : 'Decreased'}`,
    `Family & Visit: Family Meeting-${familyMeeting}, Travel-${travel}, Report-${report}, Quality-${familyMeeting > familyMeetingPrev ? 'Improved' : 'Decreased'}`,
  ];

  // Add each line of the reportContent array to the PDF
  let yOffset = 35; // Initial Y offset for the text content
  doc.setFontSize(12);
  reportContent.forEach((line, index) => {
    doc.text(line, 15, yOffset);
    yOffset += 10; // Move down for the next line
  });

  // Add a comments section
  doc.setFontSize(12);
  doc.text('Comments:', 15, yOffset + 10);
  doc.setFontSize(10);
  doc.text(comment, 15, yOffset + 20);

  // Save the PDF with the month and year in the filename
  doc.save(`Report_Summary_${currentMonth}_${currentYear}.pdf`);
  return doc.output('blob');
};

const generatePdfShare = () => {
  const doc = new jsPDF();

  // Get the current month and year
  const currentMonth = getMonthName(selectedDate);
  const currentYear = selectedDate.getFullYear();

  // Define the title of the report with the month and year
  const title = `Report Summary - ${currentMonth} ${currentYear}`;
  doc.setFontSize(16);
  doc.text(title, 15, 15);

  // Date
  doc.setFontSize(12);
  doc.text(`Date: ${selectedDate.toLocaleDateString()}`, 15, 25);

  // Define the custom layout text for the data
  const reportContent = [
    `{t('quran')}: {t('days')}-${quranStudyDays}, Study-${quranStudy}, Memorization-${quranMemorize}, Dars-${quranDars}, Quality-${quranStudyDays > quranStudyDaysPrev ? 'Improved' : 'Decreased'}`,
    `Hadith(Number): Days-${hadithStudyDays}, Study-${hadithStudy}, Memorization-${hadithMemorize}, Dars-${hadithDars}, Quality-${hadithStudyDays > hadithStudyDaysPrev ? 'Improved' : 'Decreased'}`,
    `Literature(Page Number): Islamic-${bookStudyIslamic}, Others-${bookStudyOther}, Quality-${bookStudyIslamic > bookStudyIslamicPrev ? 'Improved' : 'Decreased'}`,
    `Namaz: Jamat-${salatJamat}, Kaja-${salatKaja}, Quality-${salatJamat > salatJamatPrev ? 'Improved' : 'Decreased'}`,
    `Contact: Worker Contact-${workerContact}, Member Contact-${memberContact}, Quality-${memberContact > memberContactPrev ? 'Improved' : 'Decreased'}`,
    `Distribution: Islamic-${distributionIslamic}, Others-${distributionOther}, Quality-${distributionIslamic > distributionIslamicPrev ? 'Improved' : 'Decreased'}`,
    `Meet: Dawah Target-${meetDawahTarget}, Worker-${meetWorker}, Member-${meetMember}, Quality-${meetDawahTarget > meetDawahTargetPrev ? 'Improved' : 'Decreased'}`,
    `Org Time: Time Spend-${timeSpend}, Quality-${timeSpend > timeSpendPrev ? 'Improved' : 'Decreased'}`,
    `Miscellaneous: Self Criticism-${selfCriticism}, Social Work-${socialWork}, Debt-${debt}, Quality-${selfCriticism > selfCriticismPrev ? 'Improved' : 'Decreased'}`,
    `Family & Visit: Family Meeting-${familyMeeting}, Travel-${travel}, Report-${report}, Quality-${familyMeeting > familyMeetingPrev ? 'Improved' : 'Decreased'}`,
  ];

  // Add each line of the reportContent array to the PDF
  let yOffset = 35;
  doc.setFontSize(12);
  reportContent.forEach((line) => {
    doc.text(line, 15, yOffset);
    yOffset += 10;
  });

  // Add a comments section
  doc.setFontSize(12);
  doc.text('Comments:', 15, yOffset + 10);
  doc.setFontSize(10);
  doc.text(comment, 15, yOffset + 20);

  // Generate the PDF as a blob
  return doc.output('blob');
};


const sharePdf = async () => {
  try {
    const pdfBlob = generatePdfShare();

    // Create a file object from the blob
    const pdfFile = new File([pdfBlob], `Report_Summary_${getMonthName(selectedDate)}_${selectedDate.getFullYear()}.pdf`, {
      type: 'application/pdf',
    });

    // Check if the browser supports the Web Share API
    if (navigator.canShare && navigator.canShare({ files: [pdfFile] })) {
      await navigator.share({
        files: [pdfFile],
        title: 'Monthly Report Summary',
        text: 'Here is my report for the month.',
      });
      console.log('Report shared successfully!');
    } else {
      alert('Sharing not supported on this browser/device.');
    }
  } catch (error) {
    console.error('Error sharing PDF:', error);
  }
};


  


  useEffect(() => {
    if (!user) {
      Swal.fire({
        title: 'Warning',
        text: "You are not logged in. Please log in to access your profile.",
        icon: 'warning',
        confirmButtonText: 'Ok.'
      }).then((result) => {
        if (result['isConfirmed']){
          // Put your function here
          navigate('/');
        }
      })
      // alert('You are not logged in. Please log in to access your profile.');
      // navigate('/');
    }
  }, [user]);

  const isFutureMonth = (date) => {
    const now = new Date();
    const selectedDate = new Date(date);
    return (
      selectedDate.getFullYear() > now.getFullYear() ||
      (selectedDate.getFullYear() === now.getFullYear() && selectedDate.getMonth() > now.getMonth())
    );
  };

  useEffect(() => {
    var y = selectedDate.getFullYear();
    var m = ('0' + (selectedDate.getMonth()+1)).slice(-2);
    //console.log(m);
    setMonth(m);
    setYear(y);
  }, [selectedDate]);

  const prevMonth = () => {
    const prev = moment(selectedDate).subtract(1, 'months');
    if(prev<minimumDate){
        Swal.fire({
          title: "Warning!",
          text: "You cant go beyond that!.",
          icon: "warning"
        });
    }
    else{
      setSelectedDate(new Date(prev));
    }
  }
  const previousMonth = moment(selectedDate).subtract(1, 'months');

  const nextMonth = () => {
    const nxt = moment(selectedDate).add(1, 'months');
    if(nxt <= moment()) {
      setSelectedDate(new Date(nxt));
    }
    else{
        Swal.fire({
          title: "Warning!",
          text: "You can't see future summary.",
          icon: "warning"
        });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    API.getSummarys(token,user?.user_id)
    .then( resp =>  setAllSummary(resp))
    .catch (error => console.log(error))
  }, [gotSummary]);
 
  const getSummary = () => {
    //console.log(allsummarys);
    if(allSummary.length>0) {
      const summary = allSummary.filter((item) => item.month == month && item.year == year && item.user == user.user_id);
      const summaryPrev = allSummary.filter((item) => item.month == previousMonth && item.year == year && item.user == user.user_id);
      //setP(summary.length);
      if(summary.length != 0) {
          //console.log(summary);
          setSummaryId(summary[0].id);
          //setQuranStudy(summary.map((item) => item.quranStudy));
          setQuranStudy(summary[0].quranStudy);
          setQuranStudyDays(summary[0].quranStudyDays);
          setQuranDars(summary[0].quranDars);
          setQuranMemorize(summary[0].quranMemorize);

          setHadithStudy(summary[0].hadithStudy);
          setHadithStudyDays(summary[0].hadithStudyDays);
          setHadithDars(summary[0].hadithDars);
          setHadithMemorize(summary[0].hadithMemorize);

          setBookStudyIslamic(summary[0].bookStudyIslamic);
          setBookStudyOther(summary[0].bookStudyOther);
          setSalatJamat(summary[0].salatJamat);
          setSalatKaja(summary[0].salatKaja);
          setMemberContact(summary[0].memberContact);
          setWorkerContact(summary[0].workerContact);

          setDistributionIslamic(summary[0].distributionIslamic);
          setDistributionOther(summary[0].distributionOther);

          setMeetDawahTarget(summary[0].meetDawahTarget);
          setMeetMember(summary[0].meetMember);
          setMeetWorker(summary[0].meetWorker);
          setSocialWork(summary[0].socialWork);
          setFamilyMeeting(summary[0].familyMeeting);
          setTimeSpend(summary[0].timeSpend);
          setSelfCriticism(summary[0].selfCriticism);
          setTravel(summary[0].travel);
          setDebt(summary[0].debt);
          setReport(summary[0].report);
          setComment(summary[0].comment);
      }
      else {
          setSummaryId(0);
          setQuranStudy(null);
          setQuranStudyDays(null);
          setQuranMemorize(null);
          setQuranDars(null);

          setHadithStudy(null);
          setHadithStudyDays(null);
          setHadithMemorize(null);
          setHadithDars(null);

          setBookStudyIslamic(null);
          setBookStudyOther(null);
          setSalatJamat(null);
          setSalatKaja(null);
          setMemberContact(null);
          setWorkerContact(null);

          setDistributionIslamic(null);
          setDistributionOther(null);

          setMeetDawahTarget(null);
          setMeetMember(null);
          setMeetWorker(null);
          setSocialWork(null);
          setFamilyMeeting(null);
          setTimeSpend(null);
          setSelfCriticism(null);
          setTravel(null);
          setDebt(null);
          setReport(null);
          setComment('');
      }

      if(summaryPrev.length!=0){
        setQuranStudyDaysPrev(summaryPrev[0].quranStudyDays);
        setHadithStudyDaysPrev(summaryPrev[0].hadithStudyDays);
        setBookStudyIslamicPrev(summaryPrev[0].bookStudyIslamic);
        setSalatJamatPrev(summaryPrev[0].bookStudyIslamic);
        setMemberContactPrev(summaryPrev[0].memberContact);
        setWorkerContactPrev(summaryPrev[0].workerContact);
        setMeetWorkerPrev(summaryPrev[0].meetWorker);
        setTimeSpendPrev(summaryPrev[0].timeSpend);
        setDebtPrev(summaryPrev[0].debt);
        setReportPrev(summaryPrev[0].reportPrev);
        setTravelPrev(summaryPrev[0].travel);
        setSocialWorkPrev(summaryPrev[0].socialWork);
        setFamilyMeetingPrev(summaryPrev[0].familyMeeting);
        setSelfCriticismPrev(summaryPrev[0].selfCriticism);
      }
    }
  }

  useEffect(() => {
    if(allSummary.length > 0) {
      setLoading(false);
    } 
  }, [allSummary]);

  useEffect(() => {
    getSummary();
    //setDays(new Date(year, month, 0).getDate());
  }, [allSummary, month, year]);

  const handleSubmit = async () => {
    if (!user) {
      Swal.fire({
        title: 'Warning',
        text: "You need to log in first.",
        icon: 'warning',
        confirmButtonText: 'Ok.'
      }).then((result) => {
        if (result['isConfirmed']){
          // Put your function here
          navigate('/');
        }
      })
      return;
    }
    else{
      setLoading(true);
      const data = {
        user: user.user_id,
        year ,
        month ,
        comment,
      };
      console.log("Summary Id"+summaryId);
      if(summaryId==0)
      {
        try {
          //const apiUrl = 'http://127.0.0.1:8000/api/plan/';
          API.createSummary(data, token)
            .then( resp => {
              setLoading(false);
                console.log(resp);
                if(resp.user == user.user_id) {
                  Swal.fire({
                    title: 'Success',
                    text: "Comment successfully Added.",
                    icon: 'success',
                    confirmButtonText: 'Ok.'
                  })
                }
                else {
                  Swal.fire({
                    title: 'Warning',
                    text: "Error resp , Comment Not Added.",
                    icon: 'warning',
                    confirmButtonText: 'Ok.'
                  })
                }
            })
            .catch(error => {
              setLoading(false);
                //console.log(error);
                alert(`Submission Error. An error occurred: ${error.message}. Please try again later.`);
            });
          } catch (error) {
            setLoading(false);
            console.error('API Error:', error);
            Swal.fire({
              title: 'Warning',
              text: "Submission Error. Please try again later.",
              icon: 'warning',
              confirmButtonText: 'Ok.'
            })
          }
      }
      else{
        try {
          API.updateSummary(summaryId, data, token)
          .then( resp => {
            setLoading(false);
              //console.log(resp);
              //console.log(data);
              //console.log(reportid);
              console.log(" the response in updation : ",resp)
              if(resp.user == user.user_id) {
                Swal.fire({
                  title: 'Success',
                  text: "Comment successfully Updated!",
                  icon: 'success',
                  confirmButtonText: 'Ok.'
                })
                  setGotSummary(!gotSummary);
              }
              else {
                Swal.fire({
                  title: 'Warning',
                  text: "Oops! Comment Not Updated!",
                  icon: 'warning',
                  confirmButtonText: 'Ok.'
                })
              }
          })
        } catch (error) {
          setLoading(false);
              console.log("update time error => ",error);
              Swal.fire({
                title: 'Warning',
                text: "Error! Comment Not Updated!",
                icon: 'warning',
                confirmButtonText: 'Ok.'
              })
        }

      }
    }
      

    
  };
  

  return (
    <div>
        <Navbar/>
        <div className='report-summary-middle-part'>
          <div className='report-summary-middle-part-1st-row'>
            <div className='report-summary-middle-part-1st-row-title'>{t('summary')}</div>

            <div className='report-summary-middle-part-1st-row-lowerpart'>
              {/* <Dropdown options={Month}  placeholder="Month" />
              <Dropdown options={Year}  placeholder="Year" /> */}
              <button onClick={prevMonth}>&lt;</button>
              <DatePicker
                className='report-summary-middle-part-1st-row-datepicker'
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="MMM''yy"  // Format as "Jan'24"
                showMonthYearPicker
                filterDate={(date) => !isFutureMonth(date)}
              />
              <button onClick={nextMonth}>&gt;</button>
            </div>
            <div className='report-summary-middle-part-1st-row-total-days'>
                <div className='report-summary-middle-part-1st-row-total-days-title'>{t('total-days')}</div>
                <div21 className='report-summary-middle-part-1st-row-lowerpart-total-days'>{report}</div21>
            </div>
          </div>

          <Container className='report-summary-data-part'>
            <Row className='report-summary-data-part-1st-row'>
              <Col xs xl md lg={2} sm className='report-summary-data-part-topic'>
                {t('quran')}
              </Col>
              <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
                {t('days')}
              </Col>
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
                {quranStudyDays}
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm  className='report-summary-data-part-days'>
                {t('reading')}
              </Col>
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
                {quranStudy}
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm  className='report-summary-data-part-days'>
                {t('memorization')} 
              </Col>
              <Col xs={4} xl md lg={0.5} sm className='report-summary-data-part-Number'>
                {quranMemorize}
              </Col>
              
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
                {t('dars')}
              </Col>
              <Col xs={4} xl md lg={0.5} sm className='report-summary-data-part-Number' >
                {quranDars}
              </Col>
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
              </Col>
              <Col xs={4} xl md lg={0.5} sm className={quranStudyDays>quranStudyDaysPrev? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
                {quranStudyDays>quranStudyDaysPrev? <NorthIcon />: <SouthIcon/>}
              </Col>
              
            </Row>

            <Row className='report-summary-data-part-1st-row'>
              <Col xs xl md lg={2} sm className='report-summary-data-part-topic'>
                {t('hadith')}
              </Col>
              <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
                {t('days')}
              </Col>
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {hadithStudyDays}
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
                {t('reading')}
              </Col>

              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {hadithStudy}
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
                {t('memorization')}
              </Col>
              <Col xs={4} xl md lg={0.5} sm className='report-summary-data-part-Number'>
              {hadithMemorize}
              </Col>
              
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
                {t('dars')}
              </Col>
              <Col xs={4} xl md lg={0.5} sm className='report-summary-data-part-Number'>
              {hadithDars}
              </Col>
              
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
              </Col>
              <Col xs={4} xl md lg={0.5} sm className= {hadithStudyDays>hadithStudyDaysPrev? 'report-summary-upper-arrow': 'report-summary-lower-arrow'}>
                {hadithStudyDays>hadithStudyDaysPrev? <NorthIcon/> : <SouthIcon />}
              </Col>
              
            </Row>

            <Row className='report-summary-data-part-1st-row'>
              <Col xs xl md lg={2} sm className='report-summary-data-part-literature'>
                {t('literature')}
              </Col>
              <Col xs={4} xl md lg={1.45} sm className='report-summary-data-part-days'>
                {t('islamic')}
              </Col>'
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {bookStudyIslamic}
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
                {t('other')}
              </Col>
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
                {bookStudyOther}
              </Col>
              <Col xs={4} xl md lg={1} sm></Col>
              <Col xs={2} xl md lg={1} sm></Col>
              <Col xs={3} xl md lg={1} sm></Col>
              <Col xs={3} xl md lg={0.5} sm></Col>

              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
              </Col>
              <Col xs={4} xl md lg={0.5} sm className={bookStudyIslamic>bookStudyIslamicPrev? 'report-summary-upper-arrow':'report-summary-lower-arrow'}>
              {bookStudyIslamic>bookStudyIslamicPrev? <NorthIcon/> : <SouthIcon/>}
              
              </Col>
              
            </Row>

            <Row className='report-summary-data-part-1st-row'>
              
            <Col xs xl md lg={2} sm className='report-summary-data-part-literature'>
                {t('namaz')}
              </Col>
              <Col xs={4} xl md lg={1.45} sm className='report-summary-data-part-days'>
                {t('jamat')}
              </Col>'
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {salatJamat}
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
                {t('kadha')}
              </Col>
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
                {salatKaja}
              </Col>
              <Col xs={4} xl md lg={1} sm></Col>
              <Col xs={4} xl md lg={1} sm></Col>
              <Col xs={2} xl md lg={1} sm></Col>
              <Col xs={2} xl md lg={0.5} sm></Col>

              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
              </Col>
              <Col xs={4} xl md lg={0.5} sm className={salatJamat>salatJamatPrev? 'report-summary-upper-arrow':'report-summary-lower-arrow'}>
              {salatJamat>salatJamatPrev? <NorthIcon /> : <SouthIcon/>}
              
              </Col>

            </Row>

            <Row className='report-summary-data-part-1st-row'>

              <Col xs xl md lg={2} sm className='report-summary-data-part-literature'>
                {t('contact')}
              </Col>
              <Col xs={4} xl md lg={1.45} sm className='report-summary-data-part-days'>
                {t('worker')}
              </Col>'
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {workerContact}
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
                {t('ruqon')}
              </Col>
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
                {memberContact}
              </Col>
              <Col xs={4} xl md lg={1} sm></Col>
              <Col xs={4} xl md lg={1} sm></Col>
              <Col xs={2} xl md lg={1} sm></Col>
              <Col xs={2} xl md lg={0.5} sm></Col>

              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
              </Col>
              <Col xs={4} xl md lg={0.5} sm className={memberContact>memberContactPrev? 'report-summary-upper-arrow':'report-summary-lower-arrow'}>
              {memberContact>memberContactPrev? <NorthIcon/> : <SouthIcon />}
              
              </Col>

              
            </Row>

            <Row className='report-summary-data-part-1st-row'>

              <Col xs xl md lg={2} sm className='report-summary-data-part-literature'>
                {t('distribution')}
              </Col>
              <Col xs={4} xl md lg={1.45} sm className='report-summary-data-part-days'>
                {t('islamic')}
              </Col>'
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {distributionIslamic}
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
                {t('other')}
              </Col>
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
                {distributionOther}
              </Col>
              <Col xs={4} xl md lg={1} sm></Col>
              <Col xs={4} xl md lg={1} sm></Col>
              <Col xs={2} xl md lg={1} sm></Col>
              <Col xs={2} xl md lg={0.5} sm></Col>

              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
              </Col>
              <Col xs={4} xl md lg={0.5} sm className={distributionIslamic>distributionIslamicPrev? 'report-summary-upper-arrow': 'report-summary-lower-arrow'}>
              {distributionIslamic>distributionIslamicPrev? <NorthIcon/> : <SouthIcon />}
              
              </Col>
              
              
            </Row>

            <Row className='report-summary-data-part-1st-row'>

              <Col xs xl md lg={2} sm className='report-summary-data-part-literature'>
              {t('meet')}
              </Col>
              <Col xs={4} xl md lg={1.45} sm className='report-summary-data-part-days'>
              {t('dawah-target')}
              </Col>'
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {meetDawahTarget}
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
                {t('worker')}
              </Col>
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
                {meetWorker}
              </Col>
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('ruqon')}
              </Col>
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {meetMember}
              </Col>
              <Col xs={4} xl md lg={1} sm></Col>
              <Col xs={4} xl md lg={0.5} sm></Col>

              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
              </Col>
              <Col xs={4} xl md lg={0.5} sm className={meetDawahTarget>meetDawahTargetPrev? 'report-summary-upper-arrow': 'report-summary-lower-arrow'}>
              {meetDawahTarget>meetDawahTargetPrev? <NorthIcon/> : <SouthIcon />}
              
              </Col>

            </Row>

            <Row className='report-summary-data-part-1st-row'>

              <Col xs xl md lg={2} sm className='report-summary-data-part-literature'>
              {t('org-time')}
              </Col>
              <Col xs={4} xl md lg={1.50} sm className='report-summary-data-part-days'>
              </Col>
              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {timeSpend}
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
                
              </Col>
              <Col xs={4} xl md lg={1} sm >
                
              </Col>
              <Col xs={4} xl md lg={1} sm >
              
              </Col>
              <Col xs={4} xl md lg={1} sm >
              
              </Col>
              <Col xs={2} xl md lg={1} sm></Col>
              <Col xs={2} xl md lg={0.5} sm></Col>

              <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
              </Col>
              <Col xs={4} xl md lg={0.5} sm className={timeSpend>timeSpendPrev? 'report-summary-upper-arrow':'report-summary-lower-arrow'}>
              {timeSpend>timeSpendPrev? <NorthIcon/> : <SouthIcon />}
              
              </Col>
              
            </Row>

            <Row className='report-summary-data-part-miss-row'>
              <Col  xs xl md lg={2} sm className='report-summary-data-part-miss'>
                {t('misscellaneous')}
              </Col>
              

              <Col  xs xl md lg={4.72} sm className='report-summary-data-part-miss-1st-column'>
                <Row className='report-summary-data-part-miss-1st-column-1st-row'>
                  
                  <Col xs={3} xl md lg={3.8} sm >{t('criticism')}</Col>
                  <Col xs={3} xl md lg={2.7} sm  className='report-summary-data-part-Number'>{selfCriticism}</Col>
                  {/* <Col xs={4} xl md lg={2.7} sm  className='report-summary-data-part-days'>Quality</Col> */}
                  <Col xs={3} xl md lg={2.7} sm   className={selfCriticism>selfCriticismPrev? 'report-summary-upper-arrow':'report-summary-lower-arrow'}>
                  {selfCriticism>selfCriticismPrev? <NorthIcon/> :<SouthIcon />}
                  
                  </Col>
                  
                </Row>
                <Row className='report-summary-data-part-miss-1st-column-2nd-row'>
                  <Col xs={3} xl md lg={3.8} sm>{t('social')}</Col>
                  <Col xs={3} xl md lg={2.7} sm className='report-summary-data-part-Number'>{socialWork}</Col>
                  <Col xs={3} xl md lg={2.7} sm className={socialWork>socialWorkPrev? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
                  {socialWork>socialWorkPrev? <NorthIcon/>:<SouthIcon/>}
                  
                  </Col>
                  
                </Row>
                <Row className='report-summary-data-part-miss-1st-column-3rd-row'>
                  <Col xs={3} xl md lg={3.8} sm >{t('debt')}</Col>
                  <Col xs={3} xl md lg={2.7} sm className='report-summary-data-part-Number'>{debt}</Col>
                  <Col xs={3} xl md lg={2.7} sm className={debt>debtPrev? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
                  {debt>debtPrev? <NorthIcon/>:<SouthIcon/> }
                  
                  </Col>
                  
                </Row>
              </Col>
              

              <Col  xs xl md lg={4.72} sm className='report-summary-data-part-miss-2nd-column'>
                <Row className='report-summary-data-part-miss-2nd-column-1st-row'>
                  <Col xs={3} xl md lg={4} sm >{t('family')}</Col>
                  <Col xs={3} xl md lg={2.7} sm className='report-summary-data-part-Number'>{familyMeeting}</Col>
                  <Col xs={3} xl md lg={2.7} sm className={familyMeeting>familyMeetingPrev? 'report-summary-upper-arrow':'report-summary-lower-arrow'}>
                  {familyMeeting>familyMeetingPrev? <NorthIcon/> : <SouthIcon />}
                  
                  </Col>
                  
                </Row>
                <Row className='report-summary-data-part-miss-2nd-column-2nd-row'>
                  <Col xs={3} xl md lg={4} sm >{t('visit')}</Col>
                  <Col xs={3} xl md lg={2.7} sm className='report-summary-data-part-Number'>{travel}</Col>
                  <Col xs={3} xl md lg={2.7} sm className={travel>travelPrev? 'report-summary-upper-arrow':'report-summary-lower-arrow'}>
                  {travel>travelPrev? <NorthIcon />:<SouthIcon/>}
                  
                  </Col>
                  
                </Row>
                <Row className='report-summary-data-part-miss-2nd-column-3rd-row'>
                  <Col xs={3} xl md lg={4} sm >{t('report')}</Col>
                  <Col xs={3} xl md lg={2.7} sm className='report-summary-data-part-Number'>{report}</Col>
                  <Col xs={3} xl md lg={2.7} sm className={report>reportPrev? 'report-summary-upper-arrow':'report-summary-lower-arrow'}>
                  {report>reportPrev? <NorthIcon/> : <SouthIcon />}
                  
                  </Col>
                  
                </Row>
              </Col>

              {/* //<Col></Col> */}

            </Row>

            <div className='report-summary-data-part-comment'>
              <div1>{t('comment')}</div1>
              <textarea 
              type='text'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              />
            </div>

            <div className='report-summary-change-saved' onClick={handleSubmit}>{loading ? 'Saving...' : summaryId == 0? 'SAVE' : 'Update'}</div>

            <div className='report-summary-middle-part-bottom-row'>
                <div className='report-summary-middle-part-add-new' onClick={generatePdf}>
                  <DownloadForOfflineIcon />
                  {t('download')} 
                </div>
                <div className='report-summary-middle-part-add-new' onClick={sharePdf}>
                  <ShareIcon/>
                  {t('share')}
                </div>
            </div>

          </Container>

          
          
        </div>
        <MyFooter/>
    </div>
  )
}

export default ReportSummary