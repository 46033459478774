import React, { useContext, useEffect, useState } from 'react';
import './help.css';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import { Button } from 'react-bootstrap';
import AuthContext from '../context/AuthContext';
import SendMail from './SendMail';
import { useNavigate } from 'react-router-dom';

const Help = () => {
  const {  user } = useContext(AuthContext);

    const [activeIndex, setActiveIndex] = useState(null);
  
    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: ''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Handle form submission logic here (e.g., send form data to an API)
      alert('Your message has been sent!');
      setFormData({ name: '', email: '', message: '' }); // Clear form fields after submission
    };

    const navigate = useNavigate();

    const gotoHomePage = () => {
      navigate('/');
    };
  
    const faqData = [
      {
        question: "How can I reset my password?",
        answer: "To reset your password, go to the login page and click on 'Forgot Password'. Follow the instructions to reset it."
      },
      {
        question: "How can I update my account information?",
        answer: "Log in to your account and go to 'Account Settings' to update your personal information."
      },
      {
        question: "What should I do if I encounter a technical issue?",
        answer: "If you encounter a technical issue, please check our troubleshooting guide or contact support for further assistance."
      },
      {
        question: "How can I contact support?",
        answer: "You can contact support through our contact form or by emailing us at support@example.com."
      }
    ];

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  
    return (
        <div>
         {user &&   <Navbar/>}
            <div className="help-container">
        {/* Introduction Section */}
            <section className="help-section">
            <Button onClick={gotoHomePage}>Got to Homepage</Button>
            <h1 className="help-heading">Help Center</h1>
            <p className="help-paragraph">Welcome to our Help Center. Here you'll find answers to common questions and information on how to get support.</p>
            </section>
    
            {/* FAQ Section */}
            <section className="help-section">
            <h2 className="help-subheading">Frequently Asked Questions</h2>
            {faqData.map((item, index) => (
                <div key={index} className="faq-item">
                <div className="faq-question" onClick={() => toggleAccordion(index)}>
                    <h3 className="faq-question-title">{item.question}</h3>
                    <span className="faq-toggle-icon">
                    {activeIndex === index ? '-' : '+'}
                    </span>
                </div>
                {activeIndex === index && (
                    <div className="faq-answer">
                    <p>{item.answer}</p>
                    </div>
                )}
                </div>
            ))}
            </section>
    
            {/* Contact Form Section */}
            <section className="help-section">
            <h2 className="help-subheading">Contact Us</h2>
            <SendMail/>
            </section>
        </div>
        <MyFooter/>

        </div>
      
    );
  };

export default Help;
