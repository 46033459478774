import React, { useContext, useEffect } from 'react';
import './termscondtion.css'
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const TermsConditions = () => {
  const {  user } = useContext(AuthContext);

  const navigate = useNavigate();

  const gotoHomePage = () => {
    navigate('/');
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  
  return (
    <div>
   {user &&   <Navbar/>}
      <div className="terms-container">
      {/* Introduction Section */}
      <section className="terms-section">
      <Button onClick={gotoHomePage}>Got to Homepage</Button>
        <h1 className="terms-heading">Terms and Conditions</h1>
        <p className="terms-paragraph">These terms and conditions outline the rules and regulations for the use of our website.</p>
      </section>

      {/* Acceptance of Terms Section */}
      <section className="terms-section">
        <h2 className="terms-subheading">Acceptance of Terms</h2>
        <p className="terms-paragraph">By accessing this website, you accept these terms and conditions in full. Do not continue to use the website if you do not accept all of the terms and conditions stated on this page.</p>
      </section>

      {/* Modifications to Terms Section */}
      <section className="terms-section">
        <h2 className="terms-subheading">Modifications to Terms</h2>
        <p className="terms-paragraph">We reserve the right to modify these terms at any time. It is your responsibility to review these terms periodically to stay informed of updates.</p>
      </section>

      {/* Use of the Website Section */}
      <section className="terms-section">
        <h2 className="terms-subheading">Use of the Website</h2>
        <p className="terms-paragraph">You agree to use the website only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use of the website.</p>
      </section>

      {/* Intellectual Property Section */}
      <section className="terms-section">
        <h2 className="terms-subheading">Intellectual Property</h2>
        <p className="terms-paragraph">All content on this website, including text, images, and logos, is the intellectual property of the website owner unless otherwise stated. You may not reproduce, duplicate, or copy any content without written permission.</p>
      </section>

      {/* Termination Section */}
      <section className="terms-section">
        <h2 className="terms-subheading">Termination</h2>
        <p className="terms-paragraph">We may terminate your access to the website without notice if you violate these terms and conditions.</p>
      </section>

      {/* Governing Law Section */}
      <section className="terms-section">
        <h2 className="terms-subheading">Governing Law</h2>
        <p className="terms-paragraph">These terms and conditions are governed by the laws of the country where our company is headquartered, and you submit to the non-exclusive jurisdiction of the courts in that location.</p>
      </section>

      {/* Contact Information Section */}
      <section className="terms-section">
        <h2 className="terms-subheading">Contact Information</h2>
        <p className="terms-paragraph">If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:info@company.com" className="terms-link">info@company.com</a>.</p>
      </section>
    </div>
      <MyFooter/>
    </div>
    
  );
};

export default TermsConditions;
